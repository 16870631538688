var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("filter-base", {
    ref: "filtroBase",
    attrs: { widthFilter: 600 },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "dataFilter",
                staticClass: "px-4 py-3",
                attrs: { "label-position": "top", model: _vm.localValue },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-class",
                    attrs: { prop: "codigoPostalOrigen" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Código postal origen" },
                      model: {
                        value: _vm.localValue.codigoPostalOrigen,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "codigoPostalOrigen", $$v)
                        },
                        expression: "localValue.codigoPostalOrigen",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-class",
                    attrs: { prop: "codigoPostalDestino" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Código postal destino" },
                      model: {
                        value: _vm.localValue.codigoPostalDestino,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "codigoPostalDestino", $$v)
                        },
                        expression: "localValue.codigoPostalDestino",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "input-class", attrs: { prop: "paisOrigen" } },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "col",
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "País origen",
                              filterable: "",
                            },
                            model: {
                              value: _vm.localValue.paisOrigen,
                              callback: function ($$v) {
                                _vm.$set(_vm.localValue, "paisOrigen", $$v)
                              },
                              expression: "localValue.paisOrigen",
                            },
                          },
                          _vm._l(_vm.paises, function (item) {
                            return _c("el-option", {
                              key: item.iso2,
                              attrs: {
                                label: item.nombre.es,
                                value: item.iso2,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-class",
                    attrs: { prop: "paisDestino" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "col",
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "País destino",
                              filterable: "",
                            },
                            model: {
                              value: _vm.localValue.paisDestino,
                              callback: function ($$v) {
                                _vm.$set(_vm.localValue, "paisDestino", $$v)
                              },
                              expression: "localValue.paisDestino",
                            },
                          },
                          _vm._l(_vm.paises, function (item) {
                            return _c("el-option", {
                              key: item.iso2,
                              attrs: {
                                label: item.nombre.es,
                                value: item.iso2,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { align: "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v("Buscar")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clearFilters()
                          },
                        },
                      },
                      [_vm._v("Limpiar")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }