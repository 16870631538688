<template>
  <div class="container-box ps-3 pe-3">
    <div class="row">
      <div class="col-12 p-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Maestros</li>
            <li class="breadcrumb-item active" aria-current="page">
              Geográficos
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <Actions @triggerEvent="triggerEvent" :acciones="acciones" />
    </div>
    <div class="row">
      <el-tabs v-model="origen" @tab-click="changeTab">
        <el-tab-pane label="Geográficos" name="GEOGRAFICOS"> </el-tab-pane>
        <el-tab-pane label="Geográficos palets" name="GEOGRAFICOS_PALETS">
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="row pt-2 justify-content-between">
      <div class="col-md-5 col-sm-8 p-0">
        <Pagination @paginationChange="paginationChange" v-model="pagination" />
      </div>
      <div class="col-md-3 col-sm-2 p-0">
        <resumen-busqueda :filtrosProp="filtersLimpios" />
      </div>
      <div class="col-md-1 col-sm-12 p-0 d-flex flex-row-reverse">
        <filters @onFilterChange="onFilterChange" v-model="filters" />
      </div>
    </div>
    <div class="row pt-2">
      <el-table
        v-loading="loading"
        ref="singleTable"
        :data="allGeograficos"
        class="table-geograficos"
      >
        <el-table-column prop="geografico" label="Geográfico" />
        <el-table-column
          v-if="origen == 'GEOGRAFICOS_PALETS'"
          prop="servicio"
          label="Servicio"
        />
        <el-table-column
          prop="codigoPostalOrigen"
          label="Código Postal Origen"
          width="200"
        />
        <el-table-column
          prop="codigoPostalDestino"
          label="Código Postal Destino"
          width="200"
        />
        <el-table-column prop="paisOrigen" label="País Origen" width="120" />
        <el-table-column prop="paisDestino" label="País Destino" width="120" />
      </el-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Filters from "./components/Filters.vue";
import Actions from "@/components/Actions.vue";
import { Pagination } from "@/components";
import ResumenBusqueda from "@/components/ResumenBusqueda.vue";
export default {
  components: {
    ResumenBusqueda,
    Pagination,
    Filters,
    Actions,
  },
  data() {
    return {
      loading: false,
      origen: "GEOGRAFICOS",
      allGeograficos: null,
      filters: {
        codigoPostalOrigen: "",
        codigoPostalDestino: "",
        paisOrigen: [],
        paisDestino: [],
      },
      events: {
        onReloadTable: this.getGeograficos,
      },
      pagination: {
        actualPagina: 0,
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 30, 40, 50],
        totalPages: 0,
        totalItems: 0,
      },
      acciones: [
        {
          label: "Refrescar",
          evento: "onReloadTable",
          icon: "fa-rotate-right",
          activo: false,
        },
      ],
    };
  },
  mounted() {
    this.checkFilters();
    this.getGeograficos();
  },
  computed: {
    geograficosStatus() {
      return this.$store.state.geograficos.list.status;
    },
    filtersLimpios() {
      return this.resumeFilters();
    },
  },
  methods: {
    triggerEvent(eventKey) {
      try {
        this.events[eventKey]();
      } catch {
        this.$showMessage("Atención!", "Evento no implementado", "warning");
      }
    },
    paginationChange(pagination) {
      this.pagination = pagination;
      this.getGeograficos();
    },
    onFilterChange(value) {
      this.filters = value;
      localStorage.setItem("filtersGeograficos", JSON.stringify(this.filters));
      this.pagination.actualPagina -= this.pagination.currentPage + 1;
      this.pagination.currentPage = 1;
      this.getGeograficos();
    },
    getGeograficos() {
      if (this.origen === "GEOGRAFICOS") {
        this.$store.dispatch("geograficos/getGeograficos", {
          pagination: this.pagination,
          filters: this.cleanFilters(),
        });
      } else {
        this.$store.dispatch("geograficos/getGeograficosPalets", {
          pagination: this.pagination,
          filters: this.cleanFilters(),
        });
      }
      this.loading = true;
    },
    resumeFilters() {
      let filters = JSON.parse(JSON.stringify(this.filters));
      let filtersCleaned = {};
      if (filters.paisDestino && filters.paisDestino.length > 0)
        filtersCleaned.paisDestino = filters.paisDestino;
      if (filters.paisOrigen && filters.paisOrigen.length > 0)
        filtersCleaned.paisOrigen = filters.paisOrigen;
      if (filters.codigoPostalDestino && filters.codigoPostalDestino.length > 0)
        filtersCleaned.codigoPostalDestino = filters.codigoPostalDestino;
      if (filters.codigoPostalOrigen && filters.codigoPostalOrigen.length > 0)
        filtersCleaned.codigoPostalOrigen = filters.codigoPostalOrigen;
      return filtersCleaned;
    },
    cleanFilters() {
      let filters = JSON.parse(JSON.stringify(this.filters));
      let filtersCleaned = {};
      if (filters.paisOrigen && filters.paisOrigen.length > 0)
        filtersCleaned.paisOrigen = filters.paisOrigen;
      if (filters.paisDestino && filters.paisDestino.length > 0)
        filtersCleaned.paisDestino = filters.paisDestino;
      if (filters.codigoPostalOrigen != "")
        filtersCleaned.codigoPostalOrigen = filters.codigoPostalOrigen;
      if (filters.codigoPostalDestino != "")
        filtersCleaned.codigoPostalDestino = filters.codigoPostalDestino;
      return filtersCleaned;
    },
    checkFilters() {
      const filtersStorage = JSON.parse(
        localStorage.getItem("filtersGeograficos")
      );

      if (filtersStorage) {
        this.filters = filtersStorage;
      }
    },
    changeTab(val) {
      this.onFilterChange(this.filters);
    },
  },
  watch: {
    geograficosStatus(val) {
      if (val.loaded) {
        if (this.$store.state.geograficos.list.data) {
          this.allGeograficos = this.$store.state.geograficos.list.data.items;
          this.pagination.totalItems =
            this.$store.state.geograficos.list.data.total;
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.container-box {
  margin-top: -1.5rem;
  margin-bottom: -22px !important;
}

.table-geograficos {
  padding-left: 0px;
  padding-right: 0px;
}

.table-geograficos .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.service-warning .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0rem !important;
}
</style>
