import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
import Stats from "src/pages/Dashboard/Dashboard/Stats.vue";

// Pages
import User from "src/pages/Dashboard/Pages/UserProfile.vue";
import TimeLine from "src/pages/Dashboard/Pages/TimeLinePage.vue";
import Login from "src/pages/Dashboard/Pages/Login.vue";
import RecoverPassword from "src/pages/Dashboard/Pages/RecoverPassword.vue";
import ResetPassword from "src/pages/Dashboard/Pages/ResetPassword.vue";
import Register from "src/pages/Dashboard/Pages/Register.vue";
import Lock from "src/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "src/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "src/pages/Dashboard/Components/GridSystem.vue";
import Panels from "src/pages/Dashboard/Components/Panels.vue";
const SweetAlert = () =>
  import("src/pages/Dashboard/Components/SweetAlert.vue");
import Notifications from "src/pages/Dashboard/Components/Notifications.vue";
import Icons from "src/pages/Dashboard/Components/Icons.vue";
import Typography from "src/pages/Dashboard/Components/Typography.vue";
import Eventos from "../pages/Views/EstadosMobile/Eventos.vue";
import Transito from "../pages/Views/Transito/Transito.vue";
import Geografico from "../pages/Views/Geografico/Geografico.vue";
import Geograficos from "../pages/Views/Geograficos/Geograficos.vue";
import ServiciosSeur from "../pages/Views/ServiciosSeur/ServiciosSeur.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// TableList pages
const RegularTables = () =>
  import("src/pages/Dashboard/Tables/RegularTables.vue");
const ExtendedTables = () =>
  import("src/pages/Dashboard/Tables/ExtendedTables.vue");
const PaginatedTables = () =>
  import("src/pages/Dashboard/Tables/PaginatedTables.vue");

// Maps pages
const GoogleMaps = () => import("src/pages/Dashboard/Maps/GoogleMaps.vue");
const FullScreenMap = () =>
  import("src/pages/Dashboard/Maps/FullScreenMap.vue");
const VectorMaps = () => import("src/pages/Dashboard/Maps/VectorMapsPage.vue");

// Calendar
const Calendar = () => import("src/pages/Dashboard/Calendar/CalendarRoute.vue");

// Charts
const Charts = () => import("src/pages/Dashboard/Charts.vue");

// Envios
const Envios = () => import("src/pages/Views/Envios/Envios.vue");
const OperativaRma = () => import("src/pages/Views/OperativaRMA/Rma.vue");
const Seguimiento = () => import("src/pages/Views/Seguimiento.vue");

// Masivos
const Masivos = () => import("src/pages/Views/Masivos/Masivos.vue");

// Usuarios
const Usuarios = () => import("src/pages/Views/Usuarios/Usuarios.vue");
const MenuRoles = () => import("src/pages/Views/MenuRoles/MenuRoles.vue");

// Incidencias
const Incidencias = () => import("src/pages/Views/Incidencias/Incidencias.vue");
const IncidenciaDetalle = () =>
  import("src/pages/Views/DetalleIncidencia/DetalleIncidencia.vue");

const Forbidden = () => import("src/pages/Views/NoAuthorizedPage.vue");

// Notificaciones
const Notificaciones = () =>
  import("src/pages/Views/Notificaciones/Notificaciones.vue");
//RMA
const ImportacionesRma = () =>
  import("src/pages/Views/RMA/ImportacionesRma.vue");

// Franquicias
const Franquicias = () => import("src/pages/Views/Franquicias/Franquicias.vue");
const Agrupaciones = () =>
  import("src/pages/Views/AgrupacionesFranquicias/AgrupacionesFranquicias.vue");

const MisDatos = () =>
  import("src/pages/Views/Franquicias/EdicionFranquicia/MisDatos.vue");

// Clientes
const Clientes = () => import("src/pages/Views/Clientes/Clientes.vue");
const DireccionesHabituales = () =>
  import("src/pages/Views/Clientes/DireccionesHabituales.vue");
const Plantillas = () => import("src/pages/Views/Clientes/Plantillas.vue");
const EditarCliente = () => import("src/pages/Views/Clientes/EditCliente.vue");

// Trafico
const Trafico = () => import("src/pages/Views/Trafico/Trafico.vue");

// Estados
const Estados = () => import("src/pages/Views/Estados/Estados.vue");
const AltaEstados = () => import("src/pages/Views/Estados/AltaEstados.vue");

// Estados
const EstadosParadas = () =>
  import("src/pages/Views/EstadosParadas/EstadosParadas.vue");
const AltaEstadosParadas = () =>
  import("src/pages/Views/EstadosParadas/AltaEstadosParadas.vue");

//Tarifas
const Tarifas = () => import("src/pages/Views/Tarifas/Tarifas.vue");

//Tarifas
const FechaCierre = () => import("src/pages/Views/FechaCierre/FechaCierre.vue");

//ErrorCourier
const ErrorCourier = () =>
  import("src/pages/Views/ErrorCourier/ErrorCourier.vue");

const TarifasPartner = () =>
  import("src/pages/Views/TarifasPartner/Tarifas.vue");

//Puntos de red
const PuntosDeRed = () => import("src/pages/Views/PuntosRed/PuntosDeRed.vue");
const VerEditarPuntoDeRed = () =>
  import("src/pages/Views/PuntosRed/NuevoEditarPuntoDeRed.vue");
const VerPuntoDeRed = () =>
  import("src/pages/Views/PuntosRed/VerPuntoDeRed.vue");
/* const PlanesCombustibles = () =>
  import("src/pages/Views/PlanesCombustible/PlanesCombustible.vue"); */

const Servicios = () => import("src/pages/Views/Servicios/Servicios.vue");
const Impuestos = () => import("src/pages/Views/Impuestos/Impuestos.vue");
const Soluciones = () => import("src/pages/Views/Soluciones/Soluciones.vue");
const SolucionesReglas = () =>
  import("src/pages/Views/SolucionesRegla/SolucionesRegla.vue");

const EditarServicios = () =>
  import("src/pages/Views/Servicios/components/EditarServicio.vue");
//Plataforma
const PlataformaIntegracionConfig = () =>
  import(
    "src/pages/Views/PlataformaIntegracionConfig/PlataformaIntegracionConfig.vue"
  );
//Extraviados
const Extraviados = () => import("src/pages/Views/Extraviados/Extraviados.vue");
//TarifasCliente
const TarifasCliente = () =>
  import("src/pages/Views/TarifasCliente/TarifasCliente.vue");

const Valoraciones = () =>
  import("src/pages/Views/ControlValoracion/Valoraciones.vue");
//Borrados
const Borrados = () => import("src/pages/Views/Borrados/Borrados.vue");
const MobileQueue = () => import("src/pages/Views/MobileQueue/MobileQueue.vue");
const autorizacion = () =>
  import("src/pages/Views/Autorizacion/Autorizacion.vue");
const sorters = () =>
  import("src/pages/Views/FicherosSorter/FicherosSorter.vue");
const PlataformaIntegracion = () =>
  import("src/pages/Views/PlataformaIntegracion/PlataformaIntegracion.vue");
const NotificacionesIntegracion = () =>
  import("src/pages/Views/Integraciones/Notificaciones.vue");
const GestionConfiguracion = () =>
  import("src/pages/Views/GestionConfiguracion/Configuracion.vue");
const RenombrarServicios = () =>
  import("src/pages/Views/ServiciosRenombrados/ServiciosRenombrados.vue");
const ControlVistas = () =>
  import("src/pages/Views/QueryControl/QueryControl.vue");

const RepartidoresEventos = () =>
  import("src/pages/Views/RepartidoresEventos/RepartidoresEventos.vue");
const EnviosVistas = () =>
  import("src/pages/Views/EnviosVistas/EnviosVistas.vue");

const EstadosParadaEnvio = () =>
  import("src/pages/Views/EsatdosParadaToEnvio/Estados.vue");

//Otros
const ImportacionesOtros = () =>
  import("src/pages/Views/Otros/ImportacionesOtros.vue");

//Envios
const AltaEnvios = () => import("src/pages/Views/Envios/AltaEnvios.vue");

//Documentacion
const Documentacion = () =>
  import("src/pages/Views/Documentacion/Documentacion.vue");

const EnviosAgrupados = () =>
  import("src/pages/Views/EnviosAgrupados/EnviosAgrupados");

const NotificacionesDashboard = () =>
  import("src/pages/Views/NotificacionesDashboard/NotificacionesDashboard");

// OTRAS PLAZAS
const RecogidasOtrasPlazas = () =>
  import("src/pages/Views/RecogidasOtrasPlazas/RecogidasOtrasPlazas");

// PALETS
const Palets = () => import("src/pages/Views/Palets/Palets");

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem,
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels,
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert,
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms,
    },
    {
      path: "extended",
      name: "Extended Forms",
      component: ExtendedForms,
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms,
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard,
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      component: RegularTables,
    },
    {
      path: "extended",
      name: "Extended Tables",
      component: ExtendedTables,
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      component: FullScreenMap,
    },
    {
      path: "vector-map",
      name: "Vector Map",
      component: VectorMaps,
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
};

let recoverPage = {
  path: "/recover_password",
  name: "Recuperar Contraseña",
  component: RecoverPassword,
};
let resetPage = {
  path: "/restore_login",
  name: "Restablecer Contraseña",
  component: ResetPassword,
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register,
};

let lockPage = {
  path: "/login",
  name: "Lock",
  component: Lock,
};

let seguimiento = {
  path: "/seguimiento",
  name: "Seguimiento Envio",
  component: Seguimiento,
};
const routes = [
  {
    path: "/",
    redirect: "/franquiciadora/envios",
  },
  loginPage,
  recoverPage,
  resetPage,
  componentsMenu,
  seguimiento,
  {
    path: "/franquiciadora",
    component: DashboardLayout,
    redirect: "/franquiciadora/envios",
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview,
      },
      {
        path: "clientes",
        name: "Clientes",
        component: Clientes,
        props: true,
      },
      {
        path: "clientes/:idCliente/direccionesHabituales",
        name: "Libreta de Contactos",
        component: DireccionesHabituales,
        props: true,
      },
      {
        path: "clientes/:id/plantillas",
        name: "Envíos Programados",
        component: Plantillas,
        props: true,
      },
      {
        path: "envios",
        name: "Envíos",
        component: Envios,
      },
      {
        path: "editar/envio/:id",
        name: "Editar Envío",
        component: AltaEnvios,
      },
      {
        path: "extraviados",
        name: "Extraviados",
        component: Extraviados,
      },
      {
        path: "clientes/:idCliente",
        name: "Clientes",
        component: EditarCliente,
        props: true,
      },
      {
        path: "control",
        name: "Control",
        component: Envios,
      },
      {
        path: "importaciones",
        name: "Importaciones",
        component: Masivos,
      },
      {
        path: "incidencias/",
        name: "Incidencias",
        component: Incidencias,
        props: true,
      },
      {
        path: "incidencias/detalle/:id",
        name: "Detalle incidencia",
        component: IncidenciaDetalle,
        props: true,
      },
      {
        path: "forbidden",
        name: "Forbidden",
        component: Forbidden,
      },
      {
        path: "/franquicia/notificaciones",
        name: "Notificaciones",
        component: Notificaciones,
        props: true,
      },
      {
        path: "/franquiciadora/usuarios",
        name: "Usuarios",
        component: Usuarios,
        props: true,
      },
      {
        path: "/franquiciadora/menu-roles",
        name: "Menú roles",
        component: MenuRoles,
        props: true,
      },
      {
        path: "importacionesRma",
        name: "Importaciones RMA",
        component: ImportacionesRma,
      },
      {
        path: "importacionesOtros",
        name: "Importaciones Otros",
        component: ImportacionesOtros,
      },
      {
        path: "fichero-master",
        name: "Fichero master",
        component: ImportacionesOtros,
      },
      {
        path: "/franquiciadora/franquicias",
        name: "Franquicias",
        component: Franquicias,
        props: true,
      },
      {
        path: "/franquiciadora/franquicias/agrupaciones",
        name: "Agrupaciones",
        component: Agrupaciones,
        props: true,
      },
      {
        path: "/franquiciadora/franquicia/:id",
        name: "Franquicia",
        component: MisDatos,
        props: true,
      },
      {
        path: "/franquiciadora/franquicia",
        name: "Franquicia",
        component: MisDatos,
        props: true,
      },
      {
        path: "trafico",
        name: "Tráfico",
        component: Trafico,
        props: true,
      },
      {
        path: "trafico/:origen/:tipo/:id/envios",
        name: "Tráfico Envíos",
        component: Envios,
      },
      {
        path: "recogidas",
        name: "Recogidas",
        component: Trafico,
      },
      {
        path: "entregas",
        name: "Entregas",
        component: Trafico,
      },
      {
        path: "puntosred",
        name: "Puntos de Red",
        component: PuntosDeRed,
      },
      {
        path: "puntored/:id",
        name: "Puntos de Red",
        component: VerEditarPuntoDeRed,
      },
      {
        path: "puntoredView/:id",
        name: "Puntos de Red",
        component: VerPuntoDeRed,
      },
      {
        path: "nuevopuntored",
        name: "Puntos de Red",
        component: VerEditarPuntoDeRed,
      },
      /* {
        path: "planescombustible",
        name: "Suplemento de Combustible",
        component: PlanesCombustibles,
      }, */
      {
        path: "estados",
        name: "Estados",
        component: Estados,
      },
      {
        path: "estados/alta-estado/:id",
        name: "Alta Estados",
        component: AltaEstados,
      },
      {
        path: "estados-paradas",
        name: "Estados Paradas",
        component: EstadosParadas,
      },
      {
        path: "estados-paradas/alta-estado-parada/:id",
        name: "Alta Estados Paradas",
        component: AltaEstadosParadas,
      },
      {
        path: "rma",
        name: "RMA",
        component: OperativaRma,
      },
      {
        path: "tarifas",
        name: "Tarifas",
        component: Tarifas,
      },
      {
        path: "notificaciones-dashboard",
        name: "Portales",
        component: NotificacionesDashboard,
      },
      {
        path: "tarifasPartner",
        name: "Tarifas Partner",
        component: TarifasPartner,
      },
      {
        path: "servicios",
        name: "Servicios",
        component: Servicios,
      },
      {
        path: "editarservicio",
        name: "Editar Servicio",
        component: EditarServicios,
      },
      {
        path: "fecha-cierre",
        name: "Fecha Cierre",
        component: FechaCierre,
      },
      {
        path: "error-courier",
        name: "Errores Courier ",
        component: ErrorCourier,
      },
      {
        path: "configuracion-integracion",
        name: "Plataforma Integración Config",
        component: PlataformaIntegracionConfig,
      },
      {
        path: "autorizacion",
        name: "Api keys",
        component: autorizacion,
      },
      {
        path: "ficheros-sorter",
        name: "Ficheros Sorter",
        component: sorters,
      },
      {
        path: "plataforma-integracion",
        name: "Plataforma Integración",
        component: PlataformaIntegracion,
      },
      {
        path: "tarifas-cliente",
        name: "Tarifas Cliente",
        component: TarifasCliente,
      },
      {
        path: "control-valoracion",
        name: "Control Valoración",
        component: Valoraciones,
      },
      {
        path: "integraciones-notificaciones",
        name: "Email",
        component: NotificacionesIntegracion,
      },
      {
        path: "gestion-configuracion",
        name: "Configuración",
        component: GestionConfiguracion,
      },
      {
        path: "eventos",
        name: "Eventos",
        component: Eventos,
      },
      {
        path: "regla-transito",
        name: "Reglas de Tránsito",
        component: Transito,
      },
      {
        path: "renombrar-servicios",
        name: "Servicios Renombrados",
        component: RenombrarServicios,
      },
      {
        path: "control-vistas",
        name: "Vistas Control",
        component: ControlVistas,
      },
      {
        path: "repartidores-realizan-eventos",
        name: "Repartidores",
        component: RepartidoresEventos,
      },
      {
        path: "geograficos",
        name: "Geográficos",
        component: Geograficos,
      },
      {
        path: "geografico-servicios",
        name: "Geográfico Servicios",
        component: Geografico,
      },
      {
        path: "servicios-seur",
        name: "Servicios Seur",
        component: ServiciosSeur,
      },
      {
        path: "borrados",
        name: "Borrados",
        component: Borrados,
      },
      {
        path: "mobile-queue",
        name: "Mobile Queue",
        component: MobileQueue,
      },
      {
        path: "envios-vistas",
        name: "Vistas Entregas - Recogidas",
        component: EnviosVistas,
      },
      {
        path: "estados-parada-envio",
        name: "Servicios Parada",
        component: EstadosParadaEnvio,
      },
      {
        path: "envios-agrupados",
        name: "Envíos agrupados",
        component: EnviosAgrupados,
      },
      {
        path: "documentacion",
        name: "Documentación",
        component: Documentacion,
      },
      {
        path: "impuestos",
        name: "Impuestos",
        component: Impuestos,
      },
      {
        path: "soluciones",
        name: "Soluciones",
        component: Soluciones,
      },
      {
        path: "soluciones-reglas",
        name: "Soluciones reglas",
        component: SolucionesReglas,
      },
      {
        path: "palets",
        name: "Palets",
        component: Palets,
      },
      {
        path: "partner/salidas",
        name: "Salidas",
        component: RecogidasOtrasPlazas,
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default routes;
