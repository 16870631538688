import { createAxios } from "./axiosHttpMarconi";
export const geograficosService = { getGeograficos, getGeograficosPalets };

function getGeograficos(pagination, filters) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("franquiciadora"));
  let urlPath = `/franquiciadora/${franquiciadora._id}/geograficos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getQuery(filters);
  return axios.get(urlPath);
}

function getGeograficosPalets(pagination, filters) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("franquiciadora"));
  let urlPath = `/franquiciadora/${franquiciadora._id}/geograficos-palets`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getQuery(filters);
  return axios.get(urlPath);
}

function getQuery(filters) {
  let query = "";
  if (filters.codigoPostalOrigen) {
    query += `&codigoPostalOrigen=${filters.codigoPostalOrigen}`;
  }
  if (filters.codigoPostalDestino) {
    query += `&codigoPostalDestino=${filters.codigoPostalDestino}`;
  }
  if (filters.paisOrigen) {
    query += `&paisOrigen=${filters.paisOrigen}`;
  }
  if (filters.paisDestino) {
    query += `&paisDestino=${filters.paisDestino}`;
  }
  return query;
}
