var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-box ps-3 pe-3" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("Actions", {
          attrs: { acciones: _vm.acciones },
          on: { triggerEvent: _vm.triggerEvent },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.changeTab },
            model: {
              value: _vm.origen,
              callback: function ($$v) {
                _vm.origen = $$v
              },
              expression: "origen",
            },
          },
          [
            _c("el-tab-pane", {
              attrs: { label: "Geográficos", name: "GEOGRAFICOS" },
            }),
            _c("el-tab-pane", {
              attrs: {
                label: "Geográficos palets",
                name: "GEOGRAFICOS_PALETS",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "row pt-2 justify-content-between" }, [
      _c(
        "div",
        { staticClass: "col-md-5 col-sm-8 p-0" },
        [
          _c("Pagination", {
            on: { paginationChange: _vm.paginationChange },
            model: {
              value: _vm.pagination,
              callback: function ($$v) {
                _vm.pagination = $$v
              },
              expression: "pagination",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-3 col-sm-2 p-0" },
        [
          _c("resumen-busqueda", {
            attrs: { filtrosProp: _vm.filtersLimpios },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-1 col-sm-12 p-0 d-flex flex-row-reverse" },
        [
          _c("filters", {
            on: { onFilterChange: _vm.onFilterChange },
            model: {
              value: _vm.filters,
              callback: function ($$v) {
                _vm.filters = $$v
              },
              expression: "filters",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "row pt-2" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "singleTable",
            staticClass: "table-geograficos",
            attrs: { data: _vm.allGeograficos },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "geografico", label: "Geográfico" },
            }),
            _vm.origen == "GEOGRAFICOS_PALETS"
              ? _c("el-table-column", {
                  attrs: { prop: "servicio", label: "Servicio" },
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: {
                prop: "codigoPostalOrigen",
                label: "Código Postal Origen",
                width: "200",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "codigoPostalDestino",
                label: "Código Postal Destino",
                width: "200",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "paisOrigen", label: "País Origen", width: "120" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "paisDestino",
                label: "País Destino",
                width: "120",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 p-0" }, [
        _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
          _c("ol", { staticClass: "breadcrumb" }, [
            _c("li", { staticClass: "breadcrumb-item" }, [_vm._v("Maestros")]),
            _c(
              "li",
              {
                staticClass: "breadcrumb-item active",
                attrs: { "aria-current": "page" },
              },
              [_vm._v(" Geográficos ")]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }