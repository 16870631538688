import { createAxios } from "./axiosHttpMarconi";

export const paletsService = {
  getPalets,
  deletePalet,
  postPalet,
  updatePalet,
  getPaletById,
};

function getPalets(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/palets`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }
  urlPath += getUrlPath(filters);
  return axios.get(urlPath);
}

function getUrlPath(filters) {
  let urlPath = "";
  if (filters && filters.tipoTrafico) {
    urlPath += `&tipoTrafico=${filters.tipoTrafico}`;
  }
  return urlPath;
}

function deletePalet(sulucionReglaId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/palets/${sulucionReglaId}`;
  return axios.delete(urlPath);
}

function postPalet(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/palets`;
  return axios.post(urlPath, request);
}

function updatePalet(id, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/palets/${id}`;
  return axios.put(urlPath, body);
}
function getPaletById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/palets/${id}`;
  return axios.get(urlPath);
}
