import { createAxios } from "./axiosHttpMarconi";

export const solucionesReglasService = {
  getSolucionesRegla,
  deleteSolucionRegla,
  postSolucionRegla,
  updateSolucionRegla,
  getSolucionReglaById,
  getSolucionesReglaExcel,
};

function getSolucionesRegla(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/soluciones-reglas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }
  urlPath += getUrlPath(filters);
  return axios.get(urlPath);
}

function getUrlPath(filters) {
  let urlPath = "";
  if (filters && filters.tipoTrafico) {
    urlPath += `&tipoTrafico=${filters.tipoTrafico}`;
  }
  return urlPath;
}

function deleteSolucionRegla(sulucionReglaId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/soluciones-reglas/${sulucionReglaId}`;
  return axios.delete(urlPath);
}

function postSolucionRegla(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/soluciones-reglas`;
  return axios.post(urlPath, request);
}

function updateSolucionRegla(id, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/soluciones-reglas/${id}`;
  return axios.put(urlPath, body);
}
function getSolucionReglaById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/soluciones-reglas/${id}`;
  return axios.get(urlPath);
}

function getSolucionesReglaExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/soluciones-reglas/excel?limit=2000`;
  urlPath += getUrlPath(filters);
  return axios.get(urlPath, { responseType: "blob" });
}
