import { paletsService } from "@/_services";
export const palet = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getPaletsRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getPaletsSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getPaletsError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deletePaletRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deletePaletError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deletePaletSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postPaletRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postPaletError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postPaletSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updatePaletRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updatePaletError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updatePaletSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getPaletByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getPaletByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getPaletByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getPalets({ commit }, request) {
      commit("getPaletsRequest");
      paletsService
        .getPalets(request.pagination, request.query)
        .then((response) => {
          commit("getPaletsSuccess", response);
        })
        .catch((error) => {
          commit("getPaletsError", error);
        });
    },
    deletePalet({ commit }, id) {
      commit("deletePaletRequest");
      paletsService
        .deletePalet(id)
        .then((res) => {
          commit("deletePaletSuccess", res);
        })
        .catch((error) => {
          commit("deletePaletError", error);
        });
    },
    postPalet({ commit }, request) {
      commit("postPaletRequest");
      paletsService
        .postPalet(request)
        .then((res) => {
          commit("postPaletSuccess", res);
        })
        .catch((error) => {
          commit("postPaletError", error);
        });
    },
    updatePalet({ commit }, request) {
      commit("updatePaletRequest");
      paletsService
        .updatePalet(request.id, request.body)
        .then((res) => {
          commit("updatePaletSuccess", res);
        })
        .catch((error) => {
          commit("updatePaletError", error);
        });
    },
    getPaletById({ commit }, id) {
      commit("getPaletByIdRequest");
      paletsService
        .getPaletById(id)
        .then((res) => {
          commit("getPaletByIdSuccess", res);
        })
        .catch((error) => {
          commit("getPaletByIdError", error);
        });
    },
  },
};
