import { geograficosService } from "@/_services";

export const geograficos = {
  namespaced: true,
  state: {
    list: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getGeograficosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getGeograficosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getGeograficosSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getGeograficos({ commit }, req) {
      commit("getGeograficosRequest");
      geograficosService
        .getGeograficos(req.pagination, req.filters)
        .then((response) => {
          commit("getGeograficosSuccess", response);
        })
        .catch((error) => {
          commit("getGeograficosError", error);
        });
    },
    getGeograficosPalets({ commit }, req) {
      commit("getGeograficosRequest");
      geograficosService
        .getGeograficosPalets(req.pagination, req.filters)
        .then((response) => {
          commit("getGeograficosSuccess", response);
        })
        .catch((error) => {
          commit("getGeograficosError", error);
        });
    },
  },
};
